import axiosCall from "../../../configurations/network-services/axiosCall";

// --------------------------------------------------------------------------- New flow by Umangi from 21-09-2024

export const GetInboundShipmentPackages = (
  batch_id,
  client_id,
  shipment_id
) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/get-packages`;
  const responseType = "GET_INBOUND_SHIPMENT_PACKAGES";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const SubmitPackageLevelContentsToAmazon = (
  batch_id,
  client_id,
  shipment_id
) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/submit-package-level-contents`;
  const responseType = "SUBMIT_PACKAGE_LEVEL_CONTENTS";
  return axiosCall("post", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const CheckPackageLevelContentsToAmazon = (
  batch_id,
  client_id,
  shipment_id
) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/check-package-contents-feed-status`;
  const responseType = "CHECK_PACKAGE_LEVEL_CONTENTS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AddPackageIntoShipment = (
  batch_id,
  client_id,
  shipment_id,
  data
) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/add-packages`;
  const responseType = "ADD_PACKAGE_INTO_SHIPMENT";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetShipmentContents = (batch_id, client_id, shipment_id) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/get-shipment-contents`;
  const responseType = "GET_SHIPMENT_CONTENTS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetPackageItemsDetails = (
  batch_id,
  client_id,
  shipment_id,
  package_id
) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/${package_id}/get-package-items`;
  const responseType = "GET_PACKAGE_ITEMS_DETAILS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetShipmentContentPerBox = (batch_id, client_id, shipment_id) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/get-shipment-contents-per-box`;
  const responseType = "GET_SHIPMENT_CONTENT_PER_BOX";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const DeleteInboundShipmentPackage = (
  batch_id,
  client_id,
  shipment_id
) => {
  const path = `prep-batch/${batch_id}/${client_id}/${shipment_id}/remove-package`;
  const responseType = "DELETE_INBOUND_SHIPMENT_PACKAGE";
  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionShipPack = () => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_SHIP_PACK" });
};
