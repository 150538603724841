import { SubmitPackageLevelContents } from "./ship-pack-action";

export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_INBOUND_SHIPMENT_PACKAGES_SUCCESS":
    case "GET_INBOUND_SHIPMENT_PACKAGES_ERROR":
      return {
        ...state,
        GetInboundShipmentPackagesResponse: action.updatePayload,
      };
    case "SUBMIT_PACKAGE_LEVEL_CONTENTS_SUCCESS":
    case "SUBMIT_PACKAGE_LEVEL_CONTENTS_ERROR":
      return {
        ...state,
        SubmitPackageLevelContentsResponse: action.updatePayload,
      };
    case "CHECK_PACKAGE_LEVEL_CONTENTS_SUCCESS":
    case "CHECK_PACKAGE_LEVEL_CONTENTS_ERROR":
      return {
        ...state,
        CheckPackageLevelContentsResponse: action.updatePayload,
      };
    case "ADD_PACKAGE_INTO_SHIPMENT_SUCCESS":
    case "ADD_PACKAGE_INTO_SHIPMENT_ERROR":
      return {
        ...state,
        AddPackageIntoShipmentResponse: action.updatePayload,
      };
    case "GET_SHIPMENT_CONTENTS_SUCCESS":
    case "GET_SHIPMENT_CONTENTS_ERROR":
      return {
        ...state,
        GetShipmentContentsResponse: action.updatePayload,
      };
    case "GET_PACKAGE_ITEMS_DETAILS_SUCCESS":
    case "GET_PACKAGE_ITEMS_DETAILS_ERROR":
      return {
        ...state,
        GetPackageItemsDetailsResponse: action.updatePayload,
      };
    case "GET_SHIPMENT_CONTENT_PER_BOX_SUCCESS":
    case "GET_SHIPMENT_CONTENT_PER_BOX_ERROR":
      return {
        ...state,
        GetShipmentContentPerBoxResponse: action.updatePayload,
      };
    case "DELETE_INBOUND_SHIPMENT_PACKAGE_SUCCESS":
    case "DELETE_INBOUND_SHIPMENT_PACKAGE_ERROR":
      return {
        ...state,
        DeleteInboundShipmentPackageResponse: action.updatePayload,
      };

    case "FAKE_ACTION_SHIP_PACK":
      return [];
    default:
      return state;
  }
};
